import React from 'react';
import './footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row justify-content-center">
          {/* Twitter Logo */}
          <div className="col-auto">
            <a href="https://twitter.com/elysianmanage" target="_blank" rel="noopener noreferrer">
              <img src={process.env.PUBLIC_URL + '/Assets/twitter.png'} alt="Twitter Logo" className="social-logo" id='twitter'/>
            </a>
          </div>
          
          {/* Instagram Logo */}
          <div className="col-auto">
            <a href="https://www.instagram.com/elysianmanage/" target="_blank" rel="noopener noreferrer">
              <img src={process.env.PUBLIC_URL + '/Assets/instagram.png'} alt="Instagram Logo" className="social-logo" id='instagram'/>
            </a>
          </div>

          <h6 className='text-white p-3 mont-medium'>ELYSIAN MANAGE</h6>
        </div>
      </div>
    </footer>
  );
};

export default Footer
