import React, { useState } from 'react';
import './navbar.css';

const Navbar = () => {
  const [showNav, setShowNav] = useState(false);

  const toggleNav = () => {
    setShowNav(!showNav);
  };

  return (
    <nav className="navbar navbar-expand-md navbar-light mont-medium fs-5 fixed-top">
      <div className="container-fluid">
        {/* Navbar Brand (Logo) */}
        <a className="navbar-brand ms-3" href="#homepage">
          <img
            src='/Assets/hidgasyugd(1).png'
            alt="Logo"
            height="70vh"
            className="d-inline-block align-text-top"
          />
        </a>

        {/* Navbar Toggle Button for Small Screens */}
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleNav}
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        {/* Navbar Items */}
        <div className={`collapse navbar-collapse ${showNav ? 'show' : ''}`} id="navbarNav">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <a className="nav-link text-white" href="#homepage">
                HOME
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link text-white" href="#about">
                ABOUT
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link text-white" href="#why-us">
                WHY US?
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link text-white" href="#contact-us">
                CONTACT
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
