import './App.css';
import Navbar from './Components/Navbar/navbar';
import Home from './Components/home/home';
import About from './Components/about/about';
import Why from './Components/why-us/why-us';
import Contact from './Components/contact/contact';
import Footer from './Components/footer/footer';

function App() {
  
  return (
    <div className="App">
      <Navbar/>
      <Home/>
      <About/>
      <Why/>
      <Contact/>
      <Footer/>
    </div>
  );
}

export default App;
