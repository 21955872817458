// Home.js
import React, { useEffect } from 'react';
import './home.css';

const Home = () => {
  useEffect(() => {
    // Function to add a class for the slide-in animation
    const animateFromLeft = () => {
      const element = document.getElementById('homepage');
      element.classList.add('slide-in-from-left');
    };

    // Call the animation function once the component is mounted
    animateFromLeft();
  }, []);

  return (
    <div id='homepage' className='animate-from-left'>
      <h1 className='mont-medium fw-bold'>GROW YOUR ONLYFANS</h1>
      <h1 className='mont-medium fw-bold'>WITH US</h1>
      <h4 className='mont-light'>We help you create and promote your Onlyfans page</h4>
      <button type="button" className="btn" id='contact-button'>
        <a href='#contact-us'>Contact us</a>
      </button>
    </div>
  );
};

export default Home;
