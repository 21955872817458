
// Slide.js
import React, { useEffect, useState } from 'react';
import './slide.css';

const Slide = ({ children, direction }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const element = document.getElementById('slide-element');
      const elementTop = element.getBoundingClientRect().top;
      const offset = window.innerHeight / 2;

      setIsVisible(elementTop < offset);
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div id='slide-element' className={`slide-${direction} ${isVisible ? 'visible' : ''}`}>
      {children}
    </div>
  );
};

export default Slide;
