import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import './contact.css';


const Contact = () => {

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

    emailjs.sendForm('service_f1kav62', 'template_rrwzn65', form.current, 'ulyg_CwJ0kdsnpVt_')
      .then((result) => {
          console.log(result.text);
          window.location.reload();
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <div className="container py-5" id='contact-us'>
        <h1 className='mont-medium'>Contact us</h1>
        <p className='mont-light'>
            Ready to take your OnlyFans journey to the next level? <br></br>
            Join Elysian Manage and let us help you achieve your goals. Contact us today to get started.
        </p>
      <form id="contactForm" ref={form} onSubmit={sendEmail} className='mont-light'>
        <div className='row'>
           <div className="mb-4 col-md-6">
          <input className="form-control" id="name" type="text" name="user_name" placeholder="First Name" required/>
        </div>

        <div className="mb-4 col">
          <input className="form-control" id="name" type="text" name="user_lastName" placeholder="Last Name" required/>
        </div> 
        </div>
        

        <div className="mb-4">
          <input className="form-control" id="emailAddress" type="email" name="user_instagram" placeholder="Email Address" required/>
        </div>

        <div className="mb-4">
          <input className="form-control" placeholder="Instagram" required/>
        </div>

        <div className="mb-4">
          <input className="form-control" name="user_onlyfans" placeholder="OnlyFans ( if already exists )" />
        </div>

        <div className="d-grid">
          <button className="btn btn-lg" type="submit" id='submit-button'>
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default Contact;
