import React from 'react';
import './why-us.css';

const Why = () => {
  return (
    <div className='container-fluid' id="padding">
       <div className='row pt-5' id='why'>
        <h1 className='mont-medium text-decoration-underline'>Why us?</h1>
        <div className='col-md-3 p-5'>
            <h3 className='mont-medium'>Expert Guidance</h3>
            <p className='mont-light'>
            Our team consists of experienced personnel in Marketing.
            We know what works and can help you navigate the ins and outs of the platform.
            However, before starting we must to an audit to check the strong and weak points of your personal brand.
            </p>
        </div>

        <div className='col-md-3 p-5'>
            <h3 className='mont-medium'>Customized Strategies</h3>
            <p className='mont-light'>
            After the audit, we will prepare a personal strategy for you and outline your objectives to archive them.
            </p>
        </div>

        <div className='col-md-3 p-5'>
            <h3 className='mont-medium'>Maximized Earnings</h3>
            <p className='mont-light'>
            We will take care of your brand and we’ll be working on it everyday.
            We’ll help you make the most out of your platform while you get money and free time.
            </p>
        </div>

        <div className='col-md-3 p-5'>
            <h3 className='mont-medium'>Privacy and Security</h3>
            <p className='mont-light'>
            Your privacy and security are our top priorities. We take every precaution to ensure your
            content remains protected and accessible only to your subscribed audience.
            </p>
        </div>


    </div> 
    </div>
    
  )
}

export default Why