import { Link } from 'react-scroll';
import './about.css';
import Slide from '../slide/slide';

const About = () => {

  return (
    <Slide direction="left">
      <div className='container' id='about'>
      <div className='row'>
        <div className='col-lg-6 col-md-12'>
          {/* Make the image responsive while maintaining its size */}
          <Link to='about' smooth={true} duration={1000}>
            <img
              src={process.env.PUBLIC_URL + '/Assets/red.jpg'}
              alt="Description"
              className='img-fluid'
              id='image'
              style={{ maxWidth: '100%', height: 'auto' }}
            />
          </Link>
        </div>
        <div className='col-lg-6 col-md-12'>
          <div className="text-start" id='text-about'>
            <h1 className='mont-medium'>ABOUT US</h1>
            <p className='mont-light'>
              At Elysian Manage, we understand opportunities that come with starting an OnlyFans
              account, and we’ll make the process easier for you. Our mission is to help content creators
              develop their personal brand thanks to our proven strategy. Our team is specialized in digital
              marketing, and we’ve created a self-strategy that will work for any model.
            </p>
            <div id='why-us'>
              <p></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    </Slide>
    
  );
};

export default About;
